import React from "react"

import Layout from "../components/index/layout"
import SEO from "../components/index/seo"
import Header from "../components/index/Header"

const NotFoundPage = () => (
  <Layout>
    <Header />
    <SEO
      title="404: Not found"
      keywords={[
        `SimpleReport`,
        `SimpleReport.ca`,
        `reporting`,
        `report`,
        `safety`,
        `OHS`,
        `OSHA`,
        `OHS-reporting`,
        `OSHA-reporting`,
        `invoices`,
        `invoicing`,
        `ERP`,
        `Occupational Health and Safety`,
        `EHS`,
        `Environmental Health and Safety`,
      ]}
    />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
